<template>
  <div class="html-keyword">
    <div class="style-tool-name">
      <span>关键词样式</span>
      <el-button type="text" @click="addKeyword" class="style-tool-button--text m-l-20">+ 新增</el-button>
    </div>
    <template v-for="(item, index) in value">
      <div :key="index">
        <div class="html-keyword-item-head m-t-20">
          <span>
            <el-input v-model="item.target" class="style-tool-input" placeholder="关键词" style="width: 100px" />
            <span  class="m-l-20">
              <span>样式类型：</span>
              <el-select :value="item.decorations ? 'img' : item.fbd_code_cfg.enable_fbd ? 'fbd' : 'text'" class="style-tool-select" @input="(value) => inputDecorations(index, value)">
                <el-option label="文字模式" value="text" />
                <el-option label="图片模式" value="img" />
                <el-option label="三方代码模式" value="fbd" />
              </el-select>
            </span>
          </span>
          <span class="buttons f-r" style="text-align: right;">
            <el-button type="text" class="del-buton" @click="copy(item)" icon="el-icon-copy-document">复制</el-button>
            <el-button type="text" class="del-buton" @click="delKeyword(item)" icon="el-icon-delete">删除</el-button>
          </span>
        </div>
        <div v-if="item.fbd_code_cfg.enable_fbd" style="margin-top:15px">
          <FbdEditor
            v-model="item.fbd_code_cfg.code_template"
            :options="{ height: 150 }"
          />
        </div>
        <Decorations class="html-keyword-decoration" :decorations="item.decorations" :styleData="styleData" v-else-if="item.decorations" mode="vDom" hideText />
        <BaseStyle :styleData="styleData" :extra_font_list="extra_font_list" :value="item.style" title="" :exclude="['text_align', 'line_spacing']" style="padding-left: 0;background-color: rgba(223, 229, 235, 0.36);margin: 10px 0;" v-else>
          <div class="base-item">
            <span class="base-label" style="width: 70px">前插入：</span>
            <el-input v-model="item.style.before.content" placeholder="前插入" class="style-tool-input" />
          </div>
          <div class="base-item">
            <span class="base-label" style="width: 70px">后插入：</span>
            <el-input v-model="item.style.after.content" placeholder="前插入" class="style-tool-input" />
          </div>
          <div class="base-item">
            <el-checkbox v-model="item.is_hidden" @change="changeIsHidden($event, item)">是否隐藏</el-checkbox>
          </div>
        </BaseStyle>
      </div>

    </template>
  </div>
</template>

<script>
import BaseStyle from './BaseStyle';
import Decorations from './Decorations';
import { defaultKeyWord } from '@/scripts/styleToolDefault';
import _ from 'lodash';
import editor from 'hexin-ckeditor-doc-vue/dist/editor.umd.js';
import 'hexin-ckeditor-doc-vue/dist/editor.css';
const FbdEditor = editor.FbdEditor;
export default {
  components: {
    BaseStyle,
    Decorations,
    FbdEditor
  },
  props: {
    styleData: {
      type: Object,
      default () {
        return {
          book_default_style: {
            global_color: []
          }
        };
      }
    },
    value: {
      type: Array,
      required: true
    },
    extra_font_list: {
      type: Array,
      default () {
        return [];
      }
    }
  },
  created () {
    console.log(this.value);
  },
  methods: {
    inputDecorations (index, value) {
      const cur = this.value[index];
      cur.decorations = '';
      cur.fbd_code_cfg.enable_fbd = false;
      if (value === 'img') {
        cur.decorations = [];
      }
      if (value === 'fbd') {
        cur.fbd_code_cfg.enable_fbd = true;
      }
    },
    changeIsHidden (val, item) {
      if (!val) {
        this.$set(item, 'decorations', []);
      }
    },
    addKeyword () {
      this.$emit('input', [...this.value, defaultKeyWord()]);
    },
    delKeyword (item) {
      const index = this.value.indexOf(item);
      const arr = [...this.value];
      arr.splice(index, 1);
      this.$emit('input', arr);
    },
    copy (item) {
      this.$emit('input', [...this.value, _.cloneDeep(item)]);
    }
  }
};
</script>

<style lang="scss" scoped>
  .html-keyword {
    .del-buton {
      background-color: unset;
      padding: 0;
      color: #A2A2A2;
      min-width: unset;
      font-size: 12px;
      border: none;
      &:hover {
        opacity: 0.8;
      }
   }
   /deep/ .el-checkbox__label {
     vertical-align: bottom;
   }
   .html-keyword-item-head {
     display: flex;
     justify-content: space-between;
   }
  }
</style>
