<template>
  <el-card class="chapter-config">
    <split-pane :min-percent='20' :default-percent='30' split="vertical">
      <template slot="paneL">
        <div class="body-left">
          <div class="body-left-header">图书目录</div>
          <div class="body-left-tree p-h-10 p-b-50">
            <div style="max-height: calc(100vh - 200px); overflow: auto;">
              <RecycleScroller
                page-mode
                style="height: 100%;"
                class="scroller"
                :items="list"
                :item-size="60"
                key-field="node_id"
                v-slot="{ item }"
              >
                <div class="chapter-box" :style="marginLeft(item)">
                  <div class="chapter-inner" @click="onClickTree(item)">
                    <div class="icon-box" @click.stop>
                      <i class="el-icon-caret-bottom" v-if="!item.isLeaf" @click="closeChapter(item)" :class="{ 'closed-node': item.closed }"></i>
                    </div>
                    <div class="content-box" :class="{ 'act-node': actNode === item.node_id }">
                      <div class="style-tool-chapter-self"
                        data-default
                        :data-content-level="item.content.level"
                        :data-node-level="item.node_level"
                        :data-batch="currentBatch(item.node_id)"
                        v-html="item.content.body"
                      ></div>
                    </div>
                  </div>
                </div>
              </RecycleScroller>
            </div>
          </div>
        </div>
      </template>
      <template slot="paneR">
        <div class="body-right" v-if="config">
          <div class="body-right-header">
            <tabs v-model="tabIndex" :tabs="tabs" class="inline-tab" />
          </div>
          <div v-if="tabIndex === 0" class="style-config">
            <div class="style-lib" :class="{ closed }">
              <div class="toggle" @click="closed=!closed">版式库 <i class="el-icon-caret-top"></i></div>
              <div class="condition-box">
                <div class="left">
                </div>
                <div class="right">
                  <i class="el-icon-search"></i>
                  <input type="text" placeholder="输入关键字" v-model="condition.search">
                </div>
              </div>
              <el-tabs class="style-lib-tabs" v-model="condition.formatModel">
                <el-tab-pane v-for="(item, index) in formatList" :key="item.label + index" :name="item.value">
                  <span slot="label" class="tab-item">
                    <img :src="item.img" alt="" class="tab-item-img">
                    <span class="tab-item-text">{{item.label}}</span>
                  </span>
                  <div class="style-box">
                    <div class="item" v-for="(child, i) in formatDataList" :key="i + child.data.name" :class="{'act-item': actFormat.data.name===child.data.name}">
                      <i class="el-icon-error del" @click.stop="delComponent(child)"></i>
                      <el-image :src="child.data.img" alt="" class="img" fit="cover" />
                      <div class="text-box">
                        <i class="el-icon-success" v-if="actFormat.data.name===child.data.name"></i>
                        <span>{{child.data.name}}</span>
                      </div>
                      <div class="button-box">
                        <el-button size="mini" class="button" @click="selectFormat(child)">套用</el-button>
                      </div>
                    </div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </div>
            <el-checkbox-group :value="config.fbd.enable_fbd" @input="config.fbd.enable_fbd = !config.fbd.enable_fbd">
              <el-checkbox label="是否使用方正代码进行配置"></el-checkbox>
            </el-checkbox-group>
            <div v-if="!config.fbd.enable_fbd" class="preview">
              <span class="btn">
                <el-popover
                  placement="bottom"
                  width="400"
                  trigger="hover">
                  <div>
                    <span>透明度</span>
                    <el-slider v-model="compareStyle.opacity" :step="0.1" show-stops :max="1" size="mini" />
                  </div>
                  <div>
                    <el-button @click="$refs.compareFile.click()" type="primary" size="mini">{{ comparePic.url ? '更换比对图' : '添加对比图' }}</el-button>
                    <el-button @click="removeComparePic" type="primary" v-if="comparePic.url" size="mini">删除对比图</el-button>
                  </div>
                  <input ref="compareFile" type="file" accept="image/*" class="file-input" @change="addComparePic" />
                  <el-button size="mini" style="padding: 0" class="style-tool-button--text" slot="reference"  icon="el-icon-plus">对比图</el-button>
                </el-popover>
                <el-button class="style-tool-button--text m-l-10" @click="preview" :loading="previewLoading">预览</el-button>
              </span>

              <div class="compare-container" v-if="comparePic.url" :style="{ opacity: compareStyle.opacity }"><img :src="comparePic.url" alt="" class="compare-pic"></div>
              <ChapterPreview :styleProp="styleData" :jsonProp="[jsonProp]" ref="ChapterPreview" class="chapter-preview" />
            </div>
            <div v-if="!config.fbd.enable_fbd" class="config">
              <el-button class="style-tool-button--text right-btn" @click.stop="setComponent" icon="el-icon-plus">生成版式</el-button>
              <el-tabs v-model="actComponent">
                <el-tab-pane label="整体配置" name="_self">
                  <el-form :inline="true" size="mini" label-width="200px">
                    <el-form-item label="标题显示：">
                      <el-radio-group v-model="config.display._self">
                        <el-radio :label="true">显示标题内容</el-radio>
                        <el-radio :label="false">不显示标题内容</el-radio>
                      </el-radio-group>
                    </el-form-item>
                    <br>
                    <el-form-item label="是否根据开头数字增加字控：">
                      <el-checkbox v-model="config.config._self.is_chapter_num_zk"></el-checkbox>
                    </el-form-item>
                    <br>
                    <el-form-item label="标题另起：">
                      <el-select v-model="config.config._self.page_break" :placeholder="placeholder.config._self.page_break" :popper-append-to-body="false" class="style-tool-select" clearable>
                        <el-option v-for="(value, key) in pageBreaks" :key="key" :label="key" :value="value" />
                      </el-select>
                    </el-form-item>
                  </el-form>
                </el-tab-pane>
                <el-tab-pane label="文字样式" name="font_style" lazy>
                  <BaseStyle :styleData="styleData" :extra_font_list="styleData.extra_font_list" v-model="config.style._self" :placeholder="placeholder.style._self">
                    <template>
                      <el-form label-width="70px">
                        <el-form-item label="文本装饰：">
                          <el-select v-model="config.style._self.text_decoration" :placeholder="placeholder.style._self.text_decoration || '无'" :popper-append-to-body="false" class="style-tool-select" clearable>
                            <el-option label="下划线" value="underline" />
                            <el-option label="删除线" value="strike" />
                            <el-option label="双下划线" value="emphasis_double" />
                            <el-option label="波浪线" value="emphasis_wave" />
                            <el-option label="着重号" value="emphasis_dot" />
                          </el-select>
                        </el-form-item>
                      </el-form>
                      <el-form label-width="70px">
                        <el-form-item label="加粗：">
                          <el-select v-model="config.style._self.text_bold" :placeholder="placeholder.style._self.text_bold || '正常'" :popper-append-to-body="false" class="style-tool-select" clearable>
                            <el-option label="粗1" :value="1" />
                            <el-option label="粗2" :value="2" />
                            <el-option label="粗3" :value="3" />
                            <el-option label="粗4" :value="4" />
                          </el-select>
                        </el-form-item>
                      </el-form>
                      </template>
                  </BaseStyle>
                  <el-form :inline="true" size="mini" label-width="70px">
                    <BaseStyle class="flex-1" :styleData="styleData" :extra_font_list="styleData.extra_font_list" v-model="config.style._self.before" :exclude="['text_align', 'line_spacing']">
                      <template slot="before">
                        <el-form-item label="前插入：">
                          <el-input v-model="config.style._self.before.content" :placeholder="placeholder.style._self.before.content" class="style-tool-input" />
                        </el-form-item>
                      </template>
                    </BaseStyle>
                    <BaseStyle :styleData="styleData" class="flex-1" :extra_font_list="styleData.extra_font_list" v-model="config.style._self.after" :exclude="['text_align', 'line_spacing']">
                      <template slot="before">
                        <el-form-item label="后插入：">
                          <el-input v-model="config.style._self.after.content" :placeholder="placeholder.style._self.after.content" class="style-tool-input" />
                        </el-form-item>
                      </template>
                    </BaseStyle>
                    <el-form-item label="清除HTML样式：" label-width="120px" class="clear-html-style" v-if="false">
                      <el-checkbox-group v-model="config.html_style_clean._self">
                        <el-checkbox :label="item.value" v-for="item in htmlStyleCleanList" :key="item.value">{{item.label}}</el-checkbox>
                      </el-checkbox-group>
                    </el-form-item>
                  </el-form>
                </el-tab-pane>
                <el-tab-pane label="装饰图文" name="decorations" lazy>
                  <Decorations :decorations="config.config._self.decorations" :styleData="styleData" />
                </el-tab-pane>
                <el-tab-pane label="分栏配置" name="column" lazy>
                  <ColumnConfig  :value="config.config._self" :placeholder="placeholder.config._self" :styleData="styleData" />
                </el-tab-pane>
                <el-tab-pane label="标题位置" name="chapter_position" lazy>
                  <el-form :inline="true" size="mini" label-width="70px">
                    <el-form-item label="标题左移：">
                      <format-input type="number" v-model="config.config._self.margin.left" :placeholder="placeholder.config._self.margin.left" />
                    </el-form-item>
                    <el-form-item label="标题右移：">
                      <format-input type="number" v-model="config.config._self.margin.right" :placeholder="placeholder.config._self.margin.right" />
                    </el-form-item>
                    <br>
                    <el-form-item label="前节间距：">
                      <format-input type="number" v-model="config.config._self.margin.top" :placeholder="placeholder.config._self.margin.top" />
                    </el-form-item>
                    <el-form-item label="后节间距：">
                      <format-input type="number" v-model="config.config._self.margin.bottom" :placeholder="placeholder.config._self.margin.bottom" />
                    </el-form-item>
                  </el-form>
                </el-tab-pane>
                <el-tab-pane label="关键词" name="_keyword" lazy>
                  <HtmlKeyWord v-model="config.html._self._keyword" :styleData="styleData" :extra_font_list="styleData.extra_font_list" />
                </el-tab-pane>
              </el-tabs>
            </div>
            <FbdEditor
              v-if="config.fbd.enable_fbd"
              v-model="config.fbd.code_template"
              :options="{ height: 150 }"
            />
          </div>
          <div class="node-select-container" v-else-if="tabIndex === 1">
            <div class="node-condition-container">
              <div class="search-container">
                <el-form :inline="true" size="min" label-width="180px">
                  <el-form-item label="批量配置节点类型：">
                    <el-radio-group v-model="radio">
                      <el-radio :label="true">全部{{level[jsonProp.content.level - 1]}}级标题（筛选）</el-radio>
                      <el-radio :label="false" disabled>自定义{{level[jsonProp.content.level - 1]}}级标题</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </el-form>
              </div>
              <div>
                <el-badge :value="this.config.node_id_list.length" type="primary" class="item">
                  <el-popover
                    popper-class="style-demo-chapter-config-pop"
                    placement="bottom"
                    width="400"
                    trigger="click">
                      <div class="style-demo-chapter-config-pop-list">
                        <div class="pop-header">
                          <div class="pop-title">已加入节点</div>
                          <div class="pop-control">
                            <el-button type="text" class="icon" @click="config.node_id_list=[]" :disabled="selectedList.length === 0">一键清空</el-button>
                          </div>
                        </div>
                        <div class="pop-list" v-if="selectedList.length > 0">
                          <div class="pop-list-item" v-for="item in selectedList" :key="item.node_id">
                            <div v-html="latexParse(getItem(item))" class="content"></div>
                            <el-button type="text" @click="delNode(item.node_id)"><i class="el-icon-delete" />删除</el-button>
                          </div>
                        </div>
                        <div v-else class="empty">列表为空~~</div>
                      </div>
                      <el-button type="primary" plain slot="reference" class="pop-toggle">已选</el-button>
                  </el-popover>
                </el-badge>
              </div>
            </div>
            <div class="list">
              <div class="list-header">
                <div class="list-header-left">
                  <el-input
                    clearable
                    placeholder="请输入关键词"
                    class="search-input"
                    size="mini"
                    prefix-icon="el-icon-search"
                    v-model="conditions.listKeyword">
                  </el-input>
                </div>
                <div class="list-header-right">
                  <el-button class="style-tool-button--text" @click="showPath=!showPath" :class="{'main-color': showPath}">
                    <svg-icon name="eye" v-if="showPath"></svg-icon>
                    <svg-icon name="close-eye" v-else></svg-icon>
                    标题层级</el-button>
                  <el-checkbox v-model="checked">全选</el-checkbox>
                </div>
              </div>
              <div class="list-item" v-for="item in listData.list" :key="item.node_id">
                <el-tooltip content="Right center" placement="right" effect="light" :open-delay="100" :enterable="false">
                  <div slot="content" class="node-batch-dialog-preview">
                    <div style="max-width: 400px;">
                      <div class="content-body">
                        <span v-html="latexParse(item.content.body)"></span>
                      </div>
                    </div>
                  </div>
                  <el-checkbox :value="config.node_id_list.includes(item.node_id)" :label="true" @click.native.prevent="handleNodeList(item.node_id, item)" style="outline: none">
                    <span v-html="latexParse(getItem(item))"></span>
                  </el-checkbox>
                </el-tooltip>
                <div class="list-item-path" v-show="showPath" :title="item.path">{{item.path}}</div>
              </div>
            </div>
            <div class="footer">
              <el-pagination background ayout="prev, pager, next"
                :total="listData.total" :page-size="10" :current-page.sync="conditions.currentPage"
              ></el-pagination>
            </div>
          </div>
        </div>
      </template>
    </split-pane>
  </el-card>
</template>

<script>
import SplitPane from 'vue-splitpane';
import { iterateNode } from '@/scripts/treeHelper';
import _ from 'lodash';
import { defaultFormat } from './assets/format.js';
import { defaultChapter } from '@/scripts/styleToolDefault';
import { mergeDefault, setId } from '@/scripts/helper';
import { pageBreaks, htmlStyleCleanList, webFontFamily, replaceFontSizeMap } from '@/assets/styleTool/map';
import FormatInput from '../components/FormatInput';
import BaseStyle from '../components/BaseStyle';
import ColumnConfig from '../components/ColumnConfig';
import ChapterPreview from '../components/ChapterPreview';
import Decorations from '../components/Decorations';
import { parser } from '@/scripts/convertLatex';
import { catalog } from '../assets/componentCatalog.js';
import HtmlKeyWord from '../components/HtmlKeyWord';

// import { previewSet, pdfStatus, exportPDF } from '@/api/book'

import editor from 'hexin-ckeditor-doc-vue/dist/editor.umd.js';
import 'hexin-ckeditor-doc-vue/dist/editor.css';
const FbdEditor = editor.FbdEditor;

export default {
  components: {
    SplitPane,
    FormatInput,
    BaseStyle,
    ColumnConfig,
    ChapterPreview,
    Decorations,
    HtmlKeyWord,
    FbdEditor,
  },
  props: {
    styleData: {
      type: Object,
      reuqired: true
    },
    json: {
      type: Array,
      reuqired: true
    }
  },
  created () {
    this.init();
  },
  data () {
    return {
      htmlStyleCleanList: htmlStyleCleanList,
      pageBreaks,
      latexParse: parser,
      tabs: [
        { name: '样式配置', index: 0 },
        { name: '批量配置', index: 1 },
      ],
      compareStyle: {
        opacity: 0.5
      },
      level: ['一', '二', '三', '四', '五', '六', '七', '八', '九'],
      tabIndex: 0,
      showPath: false,
      comparePic: {
        url: '',
        mode: 'background'
      },
      jsonProp: null,
      actNode: '',
      conditions: {
        listKeyword: '',
        currentPage: 1,
      },
      actFormat: {
        data: {}
      },
      condition: {
        formatModel: '',
        search: ''
      },
      formatData: [],
      defaultFormat: defaultFormat(),
      closed: false,
      config: null,
      placeholder: null,
      actComponent: '_self',
      chapterList: [],
      timer: null,
      previewLoading: false,
      bookId: this.$route.query.book_id
        || '5fd18b2ecac877d70794356a'
    };
  },
  destroyed () {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.removeComparePic();
  },
  watch: {
    styleData: {
      immediate: true,
      deep: true,
      handler (val) {
        if (!val) return;
        this.formatStyle(val);
      }
    }
  },
  computed: {
    list () {
      return this.chapterList.filter(node => {
        return !node.path.some(item => item.closed);
      });
    },
    radio: {
      get () {
        const nodeIdList = this.config.node_id_list;
        return this.listData.filterList.every(item => nodeIdList.includes(item.node_id));
      },
      set (val) {
        if (!val) return;
        const { filterList } = this.listData;
        if (filterList.length === 0) return;
        const nodeIdList = this.config.node_id_list;
        filterList.forEach(item => {
          if (!nodeIdList.includes(item.node_id)) {
            this.removeBatchId(item.node_id);
            nodeIdList.push(item.node_id);
          }
        });
      },
    },
    checked: {
      get () {
        const nodeIdList = this.config.node_id_list;
        return this.listData.list.every(item => nodeIdList.includes(item.node_id));
      },
      set (val) {
        const { list } = this.listData;
        if (list.length === 0) return;
        const nodeIdList = this.config.node_id_list;
        if (val) {
          list.forEach(item => {
            if (!nodeIdList.includes(item.node_id)) {
              this.removeBatchId(item.node_id);
              nodeIdList.push(item.node_id);
            }
          });
        } else {
          list.forEach(item => {
            const index = nodeIdList.indexOf(item.node_id);
            if (index >= 0) nodeIdList.splice(index, 1);
          });
        }
      }
    },
    selectedList () {
      const nodeList = [];
      for (const { node } of iterateNode(this.json)) {
        if (node.node_type === 'chapter') nodeList.push(node);
      }
      const nodeIdList = this.config.node_id_list;
      const selectedList = nodeIdList.map(item => {
        const node = _.cloneDeep(nodeList.find(node => node.node_id === item));
        if (!node) return;
        const content = node.content;
        const div = document.createElement('div');
        div.innerHTML = content.body;
        content._body = div.innerText;
        return Object.assign(node, { content });
      });
      return selectedList.filter(item => item);
    },
    listData () {
      if (!this.json) {
        return {
          list: [],
          filterList: [],
          total: 0
        };
      }
      const filterData = [];
      const { listKeyword, currentPage } = this.conditions;
      const json = _.cloneDeep(this.json);
      for (const { node, parent } of iterateNode(json)) {
        node.path = parent.path ? `${parent.path} - ` + (parent?.node_name || '') : (parent?.node_name || '');
        if (node.node_type === 'chapter' && node.content.level === this.jsonProp.content.level) {
          const { body } = node.content;
          if (!listKeyword || body?.indexOf(listKeyword) >= 0) {
            const body = node.content.body;
            const temp = document.createElement('div');
            temp.innerHTML = body;
            node.content._body = listKeyword ? temp.innerText.replace(listKeyword, `<span class="style-tool-mark">${listKeyword}</span>`) : temp.innerText;
            if (listKeyword) {
              node.content.body && (node.content.body = node.content?.body.replace(listKeyword, `<span class="style-tool-mark">${listKeyword}</span>`));
            }
            if (this.actNode === node.node_id) {
              node.content._body = `<span style="background-color: #c9f7f2;">${node.content._body}</span>`;
            }
            filterData.push(node);
          }
        }
      }
      const total = filterData.length;
      return {
        total,
        filterList: filterData,
        list: filterData.slice((currentPage - 1) * 10, (currentPage - 1) * 10 + 10),
      };
    },
    formatList () {
      const formatList = catalog.module.chapter.model;
      if (!formatList) return [];
      return formatList;
    },
    formatDataList () {
      const { search } = this.condition;
      return this.formatData.filter(item => item.data.name.indexOf(search) >= 0);
    },
    chapterJson () {
      const json = _.cloneDeep(this.json);
      for (const { node } of iterateNode(json)) {
        if (node.children) {
          node.children = node.children.filter(item => item?.node_type === 'chapter');
        }
        if (!node.children.length) delete node.children;
      }
      return json;
    },
    chapterBatch () {
      const { node_batch_cfg_list } = this.styleData.components[0];
      return node_batch_cfg_list.filter(item => item?.node_type === 'chapter');
    },
    style () {
      const { styleData, chapterBatch } = this;
      const {
        default_style: { font_color, font_family_cn, font_size, font_size: default_font_size, line_spacing, line_spacing: defaultLineSpacing, text_align },
        page_cfg: { page_core_size, global_column: { column_count: default_column_count, column_gap: default_column_gap } }
      } = styleData.components[0];
      const ptToMm = (pt) => 25.4 / 72 * parseFloat(pt);
      // 栏间距 字，mm  转为没单位的 mm 值
      const defaultColumnGap = typeof default_column_gap === 'string' ? parseFloat(default_column_gap)
        : ptToMm(replaceFontSizeMap[font_size] || font_size) * default_column_gap;
      const defaultFontSizeMm = ptToMm(replaceFontSizeMap[font_size] || font_size);
      let styleStr = `
        .style-tool-chapter-self[data-default] {
          position: relative;
          width: ${(parseFloat(page_core_size.width) - defaultColumnGap) / default_column_count + 'mm'};
          color: rgb(${font_color?.rgb?.join(',')});
          font-family: ${webFontFamily[font_family_cn]};
          font-size: ${replaceFontSizeMap[font_size] || font_size};
          ${line_spacing ?
    `
              line-height: ${typeof line_spacing === 'number' ? (line_spacing + 1) * defaultFontSizeMm + 'mm' : parseFloat(line_spacing) + parseFloat(defaultFontSizeMm) + 'mm'};
              top: -${typeof line_spacing === 'number' ? line_spacing * defaultFontSizeMm / 2 + 'mm' : parseFloat(line_spacing) / 2 + 'mm'};
              padding-bottom: ${typeof line_spacing === 'number' ? line_spacing * defaultFontSizeMm / 2 + 'mm' : parseFloat(line_spacing) / 2 + 'mm'};
            ` : ''
}
          text-align: ${text_align};
        }
      `;

      chapterBatch.forEach(item => {
        const {
          id
          , config: { _self: { column: { column_count, column_gap }, is_in_column } }
          , style: { _self: { font_color, font_family_cn, font_size, line_spacing, text_align, line_height } }
          , display
        } = item;
        // TODO 行高等待世华实验
        const mergeFontSize = font_size ? (replaceFontSizeMap[font_size] || font_size) : (replaceFontSizeMap[default_font_size] || default_font_size);
        const mergeLineSpacing = line_spacing || defaultLineSpacing;
        let columnCount = default_column_count;
        let columnGap = defaultColumnGap;
        let isInColumn = true;
        if (column_count) columnCount = column_count;
        if (column_gap) {
          columnGap = typeof column_gap === 'string' ? parseFloat(column_gap)
            : ptToMm(mergeFontSize) * column_gap;
        }
        if (is_in_column === false) isInColumn = false;
        styleStr += `
          .style-tool-chapter-self[data-batch="${id}"] {
            width: ${isInColumn ? (parseFloat(page_core_size.width) - columnGap) / columnCount + 'mm' : page_core_size.width};
            ${font_color?.rgb && font_color?.rgb?.length ? `color:rgb(${font_color?.rgb?.join(',')});` : ''}
            ${font_family_cn ? `font-family: ${webFontFamily[font_family_cn]};` : ''}
            ${font_size ? `font-size: ${replaceFontSizeMap[font_size] || font_size};` : ''}
            ${line_spacing ?
    `
                line-height: ${typeof line_spacing === 'number' ? (line_spacing + 1) * ptToMm(mergeFontSize) + 'mm' : parseFloat(line_spacing) + ptToMm(mergeFontSize) + 'mm'};
                top: -${typeof line_spacing === 'number' ? line_spacing * ptToMm(mergeFontSize) / 2 + 'mm' : parseFloat(line_spacing) / 2 + 'mm'};
                padding-bottom: ${typeof line_spacing === 'number' ? line_spacing * ptToMm(mergeFontSize) / 2 + 'mm' : parseFloat(line_spacing) / 2 + 'mm'};
              `
    : ''}
              ${line_height ?
    `
                  height: ${line_height * (defaultFontSizeMm + (mergeLineSpacing === 'number' ? mergeLineSpacing * ptToMm(mergeFontSize) : parseFloat(mergeLineSpacing))) + (typeof mergeLineSpacing === 'number' ? mergeLineSpacing * ptToMm(mergeFontSize) / 2 : parseFloat(mergeLineSpacing) / 2) + 'mm'};
                  line-height: ${line_height * (defaultFontSizeMm + (mergeLineSpacing === 'number' ? mergeLineSpacing * ptToMm(mergeFontSize) : parseFloat(mergeLineSpacing))) + (typeof mergeLineSpacing === 'number' ? mergeLineSpacing * ptToMm(mergeFontSize) / 2 : parseFloat(mergeLineSpacing) / 2) + 'mm'};
                  top: unset;
                  padding-bottom: unset;
                  overflow: hidden;
                  white-space:nowrap;
                `
    : ''}
            ${text_align ? `text-align: ${text_align};` : ''}
            ${display._self ? '' : 'color: #ccc;'}
          }
        `;
      });
      return styleStr.replace(/(\n[\s\t]*\r*\n)/g, '\n').replace(/^[\n\r\n\t]*|[\n\r\n\t]*$/g, '');
    },
  },
  methods: {
    addComparePic () {
      const files = this.$refs.compareFile.files;
      if (files.length === 0) return;
      this.comparePic.url = URL.createObjectURL(files[0]);
      this.$refs.compareFile.value = '';
    },
    removeComparePic () {
      if (!this.comparePic.url) return;
      URL.revokeObjectURL(this.comparePic.url);
      this.comparePic.url = '';
    },
    isConfig ({ node_id }) {
      const { chapterBatch } = this;
      let is = false;
      chapterBatch.forEach(({ node_id_list }) => {
        if (node_id_list.includes(node_id)) is = true;
      });
      return is;
    },
    currentBatch (node_id) {
      let id = '';
      const { chapterBatch } = this;
      chapterBatch.forEach(item => {
        if (item.node_id_list.includes(node_id)) id = item.id;
      });
      return id;
    },
    closeChapter (item) {
      item.closed = !item.closed;
    },
    marginLeft ({ content: { level } }) {
      return {
        'margin-left': 18 * (level - 1) + 'px',
      };
    },
    formatStyle () {
      let style = document.querySelector('style[data-style-tool-chapter]');
      if (!style) {
        style = document.createElement('style');
        style.dataset.styleToolChapter = true;
        document.body.appendChild(style);
      }
      style.innerHTML = this.style;
    },
    selectFormat (item) {
      this.actFormat = item;
      Object.assign(this.config, _.cloneDeep(item.data.data));
    },
    setComponent () {
      const config = _.cloneDeep(this.config);
      delete config.name;
      delete config.id;
      delete config.node_id_list;
      delete config.node_type;
    },
    delNode (node_id) {
      const nodeIdList = this.config.node_id_list;
      const index = nodeIdList.indexOf(node_id);
      nodeIdList.splice(index, 1);
    },
    getItem (item) {
      return item?.content?._body;
    },
    handleNodeList (nodeId) {
      const nodeList = this.config.node_id_list;
      const index = nodeList.indexOf(nodeId);
      if (index >= 0) {
        nodeList.splice(index, 1);
      } else {
        this.removeBatchId(nodeId);
        nodeList.push(nodeId);
      }
    },
    removeBatchId (nodeId) {
      const { node_batch_cfg_list } = this.styleData.components[0];
      node_batch_cfg_list.forEach(item => {
        const index = item.node_id_list.indexOf(nodeId);
        if (index >= 0) {
          item.node_id_list.splice(index, 1);
        }
      });
    },
    init () {
      const { node_batch_cfg_list } = this.styleData.components[0];
      if (!node_batch_cfg_list) this.styleData.components[0].node_batch_cfg_list = [];
      const list = [];
      const json = _.cloneDeep(this.json);
      for (const { node, parent } of iterateNode(json)) {
        if (node.node_type === 'chapter') {
          if (node.children && !node.children.find(item => item.node_type === 'chapter')) {
            node.isLeaf = true;
          }
          node.closed = false;
          node.path = parent.path ? [...parent.path, parent] : [];
          list.push(node);
        }
      }
      this.chapterList = list;

      // 默认选中第一个节点
      if (list.length) {
        this.onClickTree(list[0]);
      }
    },
    onClickTree (node) {
      if (this.actNode === node.node_id) return;
      this.conditions = {
        listKeyword: '',
        currentPage: 1,
      };
      const { chapterBatch } = this;
      const { node_batch_cfg_list, default_style } = this.styleData.components[0];
      // 当前node_batch
      const cur = chapterBatch.find(item => item?.node_id_list.includes(node.node_id));
      if (cur) {
        this.config = cur;
      } else {
        const id = setId();
        this.config = defaultChapter({ id, name: id, node_id_list: [node.node_id], node_type: 'chapter' });
        node_batch_cfg_list.push(this.config);
      }
      // console.log(_.cloneDeep(this.config))
      this.placeholder = mergeDefault(defaultChapter(), default_style);
      const jsonProp = _.cloneDeep(node);
      delete jsonProp.children;
      this.jsonProp = jsonProp;

      // 当前 node_id
      this.actNode = node.node_id;
      this.actFormat = { data: {} };
    },
    switchFormat (val) {
      this.condition.formatType = val;
    },
  }
};
</script>
<style lang="scss">
.style-tool-mark {
  font-weight: 700;
  color: #606266;
  background-color: yellow;
}
.style-demo-chapter-config-pop {
  padding: 0;
  .style-demo-chapter-config-pop-list {
    /deep/ .el-button--text {
      border: unset;
      background-color: unset;
      color:#198cff;
      font-size: 12px;
      padding: 0;
      min-width: unset;
      &:hover {
        background-color: unset;
        color:#66b1ff;
      }
    }
    .pop-header {
      height: 60px;
      padding: 0 12px 0 23px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e4e4;
      .pop-title {
        font-size: 16px;
        font-weight: 500;
        color: #18191A;
        flex: auto;
      }
      .pop-control {
        flex: none;
      }
    }
    .empty {
      height: 300px;
      line-height: 300px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      color: #616665;
    }
    .pop-list {
      max-height: 300px;
      overflow-y: auto;
      .pop-list-item {
        height: 60px;
        border-bottom: 1px solid #e5e4e4;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 12px 0 23px;
        overflow: hidden;
        .content {
          font-size: 14px;
          font-weight: 400;
          color: #616665;
          overflow: hidden;
          text-overflow:ellipsis;
          white-space: nowrap;
        }
        /deep/ .el-button--text {
          color: #A2A2A2;
          &:hover {
            color: #d5d9de;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
$main-color: #409EFF;
$danger-color: #F56C6C;
.chapter-config {
  /deep/ .body-left-tree {
    overflow-x: hidden;
  }
  /deep/ .el-tree-node {
    width: 100%;
  }
  /deep/ .el-tree-node__content {
    height: unset;
    /deep/ .el-badge__content {
      transform: translate(-100%, -30%);
      left: 0;
      right: unset;
    }
  }
  .clear-html-style {
    ::v-deep {
      .el-form-item__content {
        width: calc(100% - 160px);
      }
      .el-checkbox {
        margin: 0 20px 10px 0;
      }
      .el-checkbox__label {
        overflow: inherit;
      }
    }
  }
  .chapter-box {
    height: 60px;
    padding: 2px;
    overflow: hidden;
    .chapter-inner {
      height: 100%;
      cursor: pointer;
      display: flex;
      .icon-box {
        flex: none;
        width: 14px;
        .el-icon-caret-bottom {
          transition: all .2s;
        }
        .closed-node {
          transform: rotate(-90deg);
        }
      }
      .content-box {
        flex: auto;
        overflow-x: hidden;
        overflow-y: auto;
        border: 1px solid #ccc;
        border-radius: 4px;
        .style-tool-chapter-self {
          border: 1px dashed #ccc;
        }
      }
      .act-node {
        border-color: #198cff;
      }
    }
  }
  /deep/ .body-left-tree {
    overflow-x: hidden;
  }
  /deep/ .el-tree-node__content {
    height: unset;
  }
  // /deep/ .el-checkbox {
  //   color: unset;
  //   max-width: 100%;
  //   // overflow: hidden;
  // }
  // /deep/ .el-checkbox__label {
  //   width: unset;
  //   max-width: 100%;
  //   line-height: 19px;
  //   overflow: hidden;
  //   text-overflow:ellipsis;
  //   white-space: nowrap;
  // }
  /deep/ .el-radio__label {
    width: unset;
  }
  /deep/ .el-card__body {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  /deep/ .splitter-pane-resizer {
    background: #fff;
    opacity: 1;
    // border: 1px solid #0003;
    z-index: 1111;
    // box-shadow: 0px 0px 3px #bdbfb4;
  }
  /deep/ .splitter-paneR {
    padding-left: 6px!important;
  }
  /deep/ .splitter-paneL {
    padding-right: 5px!important;
  }
  .body-left {
    height: 100%;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    .body-left-header {
      flex: none;
      font-size: 16px;
      font-weight: 500;
      height: 60px;
      line-height: 60px;
      text-align: center;
    }
    .body-left-tree {
      flex: auto;
      overflow-y: auto;
    }
  }
  .body-right {
    height: 100%;
    position: relative;
    .body-right-header {
      flex: none;
      display: flex;
      margin-bottom: 4px;
      .inline-tabs {
        flex: none;
      }
    }
    .style-config {
      height: calc(100% - 42px);
      display: flex;
      flex-direction: column;
      .style-lib {
        flex: none;
        position: relative;
        max-height: 400px;
        border: 1px solid #ccc;
        margin-top: 4px;
        .style-lib-tabs {
          padding: 0 20px;
          /deep/ .el-tabs__header {
            margin-bottom: unset;
          }
          /deep/ .el-tabs__item {
            height: 50px;
          }
          .tab-item {
            display: flex;
            flex-direction: column;
            .tab-item-img {
              flex: none;
              height: 30px;
            }
            .tab-item-text {
              flex: none;
              height: 20px;
              line-height: 20px;
              font-size: 12px;
              text-align: center;
            }
          }
        }
        .toggle {
          position: absolute;
          right: -1px;
          top: 0;
          transform: translate(0, -100%);
          border: 1px solid #ccc;
          border-bottom-color: transparent;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          padding: 10px 4px;
          background: #fff;
          cursor: pointer;
          .el-icon-caret-top {
            transition: transform .2s;
          }
        }
        .condition-box {
          display: flex;
          align-items: center;
          color: #333;
          height: 40px;
          overflow: hidden;
          transition: all 0.2s;
          .left {
            flex: auto;
            display: flex;
            align-items: center;
            overflow-x: auto;
            .format-item {
              font-size: 13px;
              padding: 2px 4px;
              padding-top: 0.1px;
              margin-left: 20px;
              color: #666;
              border-radius: 3px;
              cursor: pointer;
              &:hover {
                color: #fff;
                background: $main-color;
              }
            }
            .act-item {
              color: #fff;
              background: $main-color;
            }
          }
          .right {
            flex: none;
            display: flex;
            align-items: center;
            font-size: 13px;
            padding: 0 10px;
            i {
              color: #C0C4CC;
            }
            input {
              outline: none;
              border: none;
              border-bottom: 1px solid #ccc;
              padding-left: 10px;
              width: 120px;
              font-size: 13px;
              color: #919699;
              &::-webkit-input-placeholder {
                color: #DCDFE6;
              }
            }
          }
        }
        .style-box {
          transition: all 0.2s;
          display: flex;
          flex-wrap: wrap;
          padding: 10px;
          max-height: 200px;
          overflow-y: auto;
          .item {
            width: 220px;
            height: 90px;
            margin: 4px;
            border: 1px solid transparent;
            border-radius: 3px;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-left: 20px;
            cursor: pointer;
            position: relative;
            .del {
              position: absolute;
              right: 0;
              top: 0;
              transform: translate(50%, -50%);
              z-index: 2;
              display: none;
            }
            .img {
              height: 40px;
              flex: none;
            }
            .button-box {
              flex: auto;
              align-items: center;
              justify-content: center;
              display: none;
            }
            .text-box {
              flex: auto;
              align-items: center;
              justify-content: center;
              display: flex;
              .el-icon-success {
                color: $main-color;
                margin-right: 6px;
              }
            }
            &:hover {
              border-color: $main-color;
            }
            &:hover {
              .del {
                display: unset;
              }
              .button-box {
                display: flex;
              }
              .text-box {
                display: none;
              }
            }
          }
          .act-item {
            background-color: #409eff1f;
          }
        }
      }
      .closed {
        border-color: transparent;
        .toggle {
          border: 1px solid #ccc;
          border-radius: 4px;
          .el-icon-caret-top {
            transform: rotate(180deg);
          }
        }
        .condition-box {
          height: 0;
        }
        .style-box {
          overflow: hidden;
          height: 0;
          padding: 0;
        }
        .style-lib-tabs {
          height: 0;
          overflow: hidden;
        }
      }
      .preview {
        flex: none;
        height: 200px;
        background-color: #F2F6FC;
        // margin: 20px 0;
        margin-bottom: 20px;
        position: relative;
        padding: 4px;
        overflow: hidden;
        .compare-container {
          width: 100%;
          height: 100%;
          overflow: auto;
          padding: 20px;
        }
        .chapter-preview {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
          pointer-events: none;
        }
        .btn {
          position: absolute;
          right: 10px;
          top: 10px;
          z-index: 2;
        }
      }
      .config {
        flex: auto;
        overflow-y: auto;
        position: relative;
        .right-btn {
          position: absolute;
          right: 0;
          top: 0;
          line-height: 40px;
          z-index: 2;
        }
        /deep/ .el-radio__label {
          width: unset;
        }
        /deep/ .el-checkbox__label {
          width: unset;
        }
        /deep/ .el-form-item {
          margin-bottom: 0;
          padding: 10px;
          margin-right: unset;
        }
        .label, /deep/ .el-form-item__label, /deep/ .el-form-item__content {
          font-size: 14px;
          line-height: 17px;
          color: #000;
        }
        /deep/ .el-form-item__label {
          padding-right: 0;
        }
        /deep/ .el-tabs {
          height: 100%;
          .el-tabs__header {
            padding-right: 80px;
          }
          .el-tabs__content {
            overflow-y: auto;
            height: calc(100% - 55px);
          }
        }
        /deep/ .el-form,form{
          padding-bottom: 50px !important;
        }
      }
    }
    .node-select-container {
      border: 1px solid #ccc;
      height: calc(100% - 46px);
      border-radius: 6px;
      display: flex;
      flex-direction: column;
      .node-condition-container {
        flex: none;
        display: flex;
        padding: 4px;
        /deep/ .el-form-item {
          margin: 0;
        }
        .search-container {
          flex: auto;
          border: 1px solid #ccc;
          border-radius: 6px;
        }
        .pop-toggle {
          min-width: unset;
          padding: 12px 12px;
          margin-left: 4px;
        }
      }
      .list {
        padding: 10px 20px;
        flex: auto;
        overflow-y: auto;
        .list-header {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          margin-bottom: 20px;
          .list-header-left {
            padding-left: 10px;
            flex: auto;
            .search-input {
              width: 200px;
            }
          }
          .list-header-right {
            flex: none;
            display: flex;
            .style-tool-button--text {
              margin-right: 10px;
              color: rgb(48, 49, 51);
            }
            .main-color {
              color: $main-color
            }
            /deep/ .el-checkbox__label {
              width: unset;
              padding-right: 14px;
              vertical-align: bottom;
            }
          }
        }
        .list-container {
          border: 1px solid #DEDEDE;
          flex: auto;
          overflow: hidden;
        }
        .list-item {
          font-size: 12px;
          height: 50px;
          color: #919599;
          /deep/ .el-checkbox__label {
            vertical-align: bottom;
          }
          .list-item-path {
            padding-left: 30px;
            width: auto;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
          }
        }
      }
      .footer {
        flex: none;
        text-align: center;
        padding: 10px;
      }
    }
  }
}
</style>
