import katex from 'katex';
const renderFang = function renderFang (dom, data) {
  while (dom.firstChild) dom.removeChild(dom.firstChild);
  const container = document.createElement('span');
  const size = data.width;
  const collapse = data.collapse;
  for (let i = 0; i < data.length; i++) {
    const span = document.createElement('span');
    span.style.width = size;
    span.style.height = size;
    span.style.boxSizing = 'border-box';
    span.style.display = 'inline-block';
    span.style.lineHeight = '1';
    span.style.verticalAlign = 'middle';
    span.style.border = '1px #000 solid';
    if (i !== 0) {
      if (collapse) {
        span.style.borderLeft = 'none';
      } else {
        span.style.marginLeft = '0.9mm';
      }
    }
    span.style.position = 'relative';
    container.appendChild(span);
  }
  dom.appendChild(container);
};

const renderTian = function renderTian (dom, data) {
  while (dom.firstChild) dom.removeChild(dom.firstChild);
  const container = document.createElement('span');
  const fontSize = data.fontSize || '9pt';
  let cellSize = data.cellSize || '8mm';
  let unit = cellSize.match(/[a-z%]+$/)?.[0] || '';
  if (!unit) {
    unit = 'px';
    cellSize += unit;
  }
  const halfSize = parseFloat(cellSize) / 2 - 0.264583 + unit;
  const pinyin = data.pinyin?.trim().split(/,/);
  const lineType = data.innerLineType || 'dashed';
  for (let i = 0; i < data.length; i++) {
    const ruby = document.createElement('ruby');
    const span = document.createElement('span');
    span.style.width = cellSize;
    span.style.height = cellSize;
    span.style.boxSizing = 'border-box';
    span.style.display = 'inline-block';
    span.style.lineHeight = '1';
    span.style.verticalAlign = 'middle';
    span.style.border = '1px #000 solid';
    if (i !== 0) span.style.borderLeft = 'none';
    span.style.position = 'relative';
    'vh'.split('').forEach(function (side) {
      const inner = document.createElement('span');
      inner.innerText = ' ';
      inner.style.position = 'absolute';
      inner.style.boxSizing = 'border-box';
      inner.style[side === 'v' ? 'borderLeft' : 'borderTop'] = '1px #666 ' + lineType;
      inner.style[side === 'v' ? 'width' : 'height'] = '1px';
      inner.style[side === 'v' ? 'top' : 'left'] = '1px';
      inner.style[side === 'v' ? 'bottom' : 'right'] = '1px';
      inner.style[side === 'v' ? 'left' : 'top'] = halfSize;
      span.appendChild(inner);
    });
    ruby.appendChild(span);
    const rt = document.createElement('rt');
    rt.style.maxWidth = cellSize;
    rt.style.whiteSpace = 'nowrap';
    rt.style.textAlign = 'center';
    rt.style.fontSize = fontSize;
    rt.style.boxSizing = 'border-box';
    if (pinyin?.[i]) rt.innerText = pinyin[i];
    ruby.appendChild(rt);
    container.appendChild(ruby);
  }
  dom.appendChild(container);
};

const renderPinyin = function renderPinyin (dom, data) {
  while (dom.firstChild) dom.removeChild(dom.firstChild);
  const container = document.createElement('span');
  const fontSize = data.fontSize || '9pt';
  const pinyin = data.pinyin.trim().split(/,/);
  if (data.text) {
    const text = data.text.split('');
    for (let i = 0; i < pinyin.length; i++) {
      const ruby = document.createElement('ruby');
      const span = document.createElement('span');
      if (text[i] === ' ') {
        span.innerHTML = '&emsp;';
      } else {
        span.innerText = text[i];
      }
      ruby.appendChild(span);
      const rt = document.createElement('rt');
      rt.style.textAlign = 'center';
      rt.style.fontSize = fontSize;
      rt.innerText = pinyin[i];
      ruby.appendChild(rt);
      if (i !== 0) ruby.style.paddingLeft = '2px';
      container.appendChild(ruby);
    }
  } else {
    for (let j = 0; j < pinyin.length; j++) {
      const span = document.createElement('span');
      span.style.fontSize = fontSize;
      span.innerText = pinyin[j];
      if (j !== 0) span.style.paddingLeft = '2px';
      container.appendChild(span);
    }
  }
  dom.appendChild(container);
};

const formatNode = function formatNode (node) {
  if (node.dataset.label === 'latex') { // 公式渲染
    node.innerHTML = katex.renderToString(node.dataset.value, {
      output: 'html',
      displayMode: true,
      strict: false,
      throwOnError: false
    });
  }
  if (node.dataset.label === 'fang') {
    renderFang(node, {
      width: node.dataset.width,
      collapse: node.dataset.collapse,
      length: +node.dataset.length
    });
  }
  if (node.dataset.label === 'tian') {
    renderTian(node, {
      fontSize: node.dataset.fontSize,
      cellSize: node.dataset.cellSize,
      pinyin: node.dataset.pinyin,
      innerLineType: node.dataset.innerLineType,
      length: +node.dataset.length
    });
  }
  if (node.dataset.label === 'pinyin') {
    renderPinyin(node, {
      fontSize: node.dataset.fontSize,
      pinyin: node.dataset.pinyin,
      text: node.dataset.text
    });
  }
  if (node.tagName === 'IMG') {
    if (/\.tiff?$/.test(node.src)) {
      node.src += '?x-oss-process=image/format,jpg';
    }
  }
};

export {
  renderFang,
  renderTian,
  renderPinyin,
  formatNode
};
