<template>
  <div class="decorations">
    <div class="style-tool-name">
      <span class="decoration-title">装饰图文</span>
      <el-button @click="addDecoration('image')"  class="style-tool-button--text m-l-20">+ 新增图片</el-button>
      <el-button @click="addDecoration('text')"  class="style-tool-button--text m-l-20" v-if="!hideText">+ 新增文字</el-button>
      <el-button @click="addDecoration('dynamic_image')"  class="style-tool-button--text m-l-20">+ 标题动态图</el-button>
    </div>
    <input ref="file" type="file" class="file-input" :accept="(actImg&&activeFileName !=='url') ? `image/tiff, application/postscript` : `image/jpeg, image/png`" :value="fileVal" @change="fileChange">
    <!-- 装饰图列表 -->
    <div class="decoration-item" v-for="(item, index) in decorations" :key="item.url + index">
      <div class="decoration-header">
        <span class="label">
          <el-tooltip class="item" effect="dark" content="解锁宽高等比" placement="top-start" v-if="locks[index]">
            <svg-icon name="closeLock" @click.native="handleLock(false, index)"></svg-icon>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="锁定宽高等比" placement="top-start" v-else>>
            <svg-icon name="openLock" @click.native="handleLock(true, index)"></svg-icon>
          </el-tooltip>
          <span v-if="item.type==='image'">图片{{index + 1}}：</span>
          <span v-if="item.type==='text'">文字{{index + 1}}：</span>
          <span v-if="item.type==='dynamic_image'">
            动态图
            <el-button @click="addDecoration('dynamic_image_row')"  class="style-tool-button--text m-l-20">+ 条件</el-button>
          </span>
          </span>
        <span class="buttons f-r"><el-button class="del-button" @click="delDecoration(index)">删除</el-button></span>
      </div>
      <el-form :inline="true" size="mini" label-width="70px">
        <template v-if="item.type==='dynamic_image'">
          <el-form-item label="放置位置：">
            <el-select v-model="item.position" placeholder="请选择" class="style-tool-select" :popper-append-to-body="false" @change="positionChange($event, item)">
              <el-option
                v-for="item in decorationPosition"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="左侧距离：">
            <format-input type="number" v-model="item.margin.left" />
          </el-form-item>
          <el-form-item label="右侧距离：">
            <format-input type="number" v-model="item.margin.right" />
          </el-form-item>
          <el-form-item label="向上移动：" v-if="index === 0 && mode === 'dom'">
            <format-input type="number" v-model="item.margin.top" />
          </el-form-item>
            <el-form-item label='图片偏移:'>
              <unit-input min="-1000" max="1000" step="1" v-model="item.offset" unit="mm" />
              <span class="unit">&nbsp;mm</span>
            </el-form-item>
          <div v-for="(items,indexs) in item.dynamic_image_cfg" :key="indexs" class="dynamic_image_cfg_container">
          <span class="buttons f-r deldynamic_image"><el-button class="del-button" @click="deldynamic_image(index,indexs)">删除</el-button></span>
          <el-form-item label="图片素材：">
              <el-badge :is-dot="!!items.url">
                <el-button class="style-tool-button" style="padding: 0" @click="uploadAliasImg(items,'url')">上传</el-button>
              </el-badge>
            </el-form-item>
            <el-form-item label="备用图：">
              <el-badge :is-dot="!!items.alias_url">
                <el-button class="style-tool-button" style="padding: 0" @click="uploadAliasImg(items,'alias_url')">上传</el-button>
              </el-badge>
            </el-form-item>
            <el-form-item label="图片长度：">
              <unit-input min="0" max="1000" step="1" v-model="items.size.width" unit="mm" @input="handleWidth($event, items, index)" />
          <span class="unit">&nbsp;mm</span>
            </el-form-item>
            <el-form-item label="图片高度：">
              <unit-input min="0" max="1000" step="1" v-model="items.size.height" unit="mm" @input="handleHeight($event, items, index)" />
          <span class="unit">&nbsp;mm</span>
            </el-form-item>
            <el-form-item label="标题文字长度：" label-width="100px">
              <unit-input min="0" max="1000" step="1" v-model="items.range.start" unit="" />
              -
              <unit-input min="0" max="1000" step="1" v-model="items.range.end" unit="" />
            </el-form-item>
          </div>
        </template>
        <template v-else>
<BaseStyle :extra_font_list="styleData.extra_font_list" :styleData="styleData" v-model="item.style" :exclude="['text_align', 'line_spacing']" v-if="item.type === 'text'">
          <template slot="before">
            <el-form-item label="文本内容：">
              <el-input v-model="item.text" class="style-tool-input" />
            </el-form-item>
          </template>
        </BaseStyle>
        <el-form-item label="备用图：" v-if="item.type === 'image'">
          <el-badge :is-dot="!!item.alias_url">
            <el-button class="style-tool-button" style="padding: 0" @click="uploadAliasImg(item)">上传</el-button>
          </el-badge>
        </el-form-item>
        <el-form-item label="放置位置：" v-if="index === 0">
          <el-select v-model="item.position" placeholder="请选择" class="style-tool-select" :popper-append-to-body="false" @change="positionChange($event, item)">
            <el-option
              v-for="item in decorationPosition"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="图片宽度：" v-if="item.type === 'image'">
          <unit-input min="0" max="1000" step="1" v-model="item.size.width" unit="mm" @input="handleWidth($event, item, index)" />
          <span class="unit">&nbsp;mm</span>
        </el-form-item>
        <el-form-item label="图片高度：" v-if="item.type === 'image'">
          <unit-input min="0" max="1000" step="1" v-model="item.size.height" unit="mm" @input="handleHeight($event, item, index)" />
          <span class="unit">&nbsp;mm</span>
        </el-form-item>
        <br>
        <el-form-item label="左侧距离：">
          <format-input type="number" v-model="item.margin.left" />
        </el-form-item>
        <el-form-item label="右侧距离：">
          <format-input type="number" v-model="item.margin.right" />
        </el-form-item>
        <el-form-item label="向上移动：" v-if="index === 0 && mode === 'dom'">
          <format-input type="number" v-model="item.margin.top" />
        </el-form-item>
        <el-row v-if="mode === 'dom'">
          <el-form-item :label="index === 0 ? '标题偏移：' : item.type === 'image' ? '图片偏移：' : '文字偏移：'">
            <unit-input min="-1000" max="1000" step="1" v-model="item.offset" unit="mm" />
            <span class="unit">&nbsp;mm</span>
          </el-form-item>
        </el-row>
        <el-row v-if="mode === 'vDom'">
          <el-form-item label="垂直位置">
            <el-select v-model="item.vertical_align" placeholder="请选择" class="style-tool-select" :popper-append-to-body="false" clearable>
              <el-option label="居中" value="center"></el-option>
              <el-option label="基线对齐" value="bottom"></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        </template>

      </el-form>
    </div>
  </div>
</template>
<script>
import { getDPI, setId, mergePeer } from '@/scripts/helper';
import { getDefaultDecoration, getDefaultDynamicDecoration } from '@/scripts/styleToolDefault';
import BaseStyle from './BaseStyle';
import UnitInput from './UnitInput';

import FormatInput from './FormatInput';
export default {
  components: {
    UnitInput,
    BaseStyle,
    FormatInput
  },
  props: {
    hideText: {
      type: Boolean
    },
    decorations: {
      type: Array,
      required: true
    },
    styleData: {
      type: Object
    },
    mode: {
      type: String,
      // dom 正常dom vDom 伪类
      default: 'dom'
    }
  },
  data () {
    return {
      process: 0,
      files: null,
      ossUrl: '',
      dpi: getDPI(),
      fileVal: '',
      locks: [],
      actImg: null,
      decorationPosition: [{
        label: '左侧',
        value: 'before'
      }, {
        label: '右侧',
        value: 'after'
      }],
      activeFileName: ''
    };
  },
  methods: {
    addDecoration (type) {
      if (type === 'image') {
        this.actImg = null;
        this.$nextTick(function () {
          this.$refs.file.click();
        });
        return;
      }
      if (type === 'text') {
        if (this.decorations.length) {
          this.decorations.push(mergePeer(getDefaultDecoration(), { id: setId(), type: 'text' }));
        } else {
          this.$message.info('没有主图');
        }
      }
      if (type === 'dynamic_image') {
        if (this.decorations.find((item) => { return item.type === 'dynamic_image'; })) {
          this.$message.info('动态图只能添加一个');
          return;
        }
        const dynamic_image_cfg = mergePeer(getDefaultDynamicDecoration());
        const decoration = mergePeer(getDefaultDecoration(), { id: setId(), type: 'dynamic_image' });
        decoration.dynamic_image_cfg = [dynamic_image_cfg];
        this.decorations.push(decoration);
      }
      if (type === 'dynamic_image_row') {
        const dynamic_image = this.decorations.find((item) => { return item.type === 'dynamic_image'; });
        if (!dynamic_image.dynamic_image_cfg) {
          this.$set(dynamic_image, 'dynamic_image_cfg', []);
        }
        dynamic_image.dynamic_image_cfg.push(mergePeer(getDefaultDynamicDecoration()));
      }
    },
    uploadAliasImg (item, name) {
      this.actImg = item;
      this.activeFileName = name;
      this.$nextTick(function () {
        this.$refs.file.click();
      });
    },
    handleWidth (val, item, index) {
      if (this.locks[index] && val !== '') {
        const img = new Image();
        img.src = item.url + '?x-oss-process=image/format,jpg';
        img.onload = () => {
          item.size.height = Math.round((parseFloat(val) / (img.width / this.dpi.y * 25.4) * (img.height / this.dpi.x * 25.4)) * 10) / 10 + 'mm';
        };
        return;
      }
      item.size.width = val;
    },
    handleHeight (val, item, index) {
      if (this.locks[index] && val !== '') {
        const img = new Image();
        img.src = item.url + '?x-oss-process=image/format,jpg';
        img.onload = () => {
          item.size.width = Math.round((parseFloat(val) / (img.height / this.dpi.y * 25.4) * (img.width / this.dpi.x * 25.4)) * 10) / 10 + 'mm';
        };
        return;
      }
      item.size.height = val;
    },
    handleLock (val, index) {
      this.locks.splice(index, 1, val);
    },
    positionChange (val, item) {
      item.margin.left = '0mm';
      item.margin.right = '0mm';
    },
    delDecoration (index) {
      if (index === 0 && this.decorations.length > 1) {
        this.$message.warning('不能直接删除主图');
        return;
      }
      this.decorations.splice(index, 1);
    },
    fileChange (evt) {
      const file = evt.target.files[0];
      this.process = 0;
      this.files = file;
      this.fileUploadOss();
    },
    setDecoration (url) {
      if (this.actImg) {
        if (this.activeFileName) {
          this.actImg[this.activeFileName] = url;
          return;
        }
        this.actImg.alias_url = url;
        return;
      }
      const decoration = mergePeer(getDefaultDecoration(), { url, id: setId(), type: 'image' });
      const img = new Image();
      img.src = url + '?x-oss-process=image/format,jpg';
      img.onerror = () => {
        this.decorations.push(decoration);
      };
      img.onload = () => {
        let imgWidth = img.width;
        let imgHeight = img.height;
        let nodeWidth = this.nodeWidth;
        if (!nodeWidth) {
          if (Array.isArray(this.node)) {
            nodeWidth = document.querySelector(`[data-node-id="${this.node[0]}"]`)?.offsetWidth;
          } else if (this.node instanceof HTMLElement) {
            nodeWidth = this.node?.offsetWidth;
          } else {
            nodeWidth = document.querySelector('[data-node-id]')?.offsetWidth;
          }
        }
        if (nodeWidth && nodeWidth < imgWidth) {
          imgHeight = imgHeight * nodeWidth / imgWidth;
          imgWidth = nodeWidth;
        }
        decoration.size = {
          width: Math.round(imgWidth / this.dpi.x * 25.4) + 'mm',
          height: Math.round(imgHeight / this.dpi.y * 25.4) + 'mm'
        };
        this.decorations.push(decoration);
      };
    },
    uploadProgress (file, event) {
      let process = event.loaded / event.total;
      process = Math.floor(process * 100) + '%';
      this.process = process;
    },
    deldynamic_image (index, indexs) {
      this.decorations[index].dynamic_image_cfg.splice(indexs, 1);
    }
  },
  watch: {
    decorations: {
      deep: true,
      immediate: true,
      handler (val) {
        const locks = [];
        val.forEach((item, index) => {
          locks[index] = this.locks[index] === undefined ? true : this.locks[index];
        });
        this.locks = locks;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
  .decorations {
    padding: 10px 0;
    width: 100%;
    .decoration-title {
      height: 17px;
      font-size: 14px;
      line-height: 17px;
      color: #000;
      text-align: right;
      width: 70px;
      font-weight: normal;
      display: inline-block;
    }
    .del-button {
      background-color: unset;
      padding: 0;
      color: #A2A2A2;
      min-width: unset;
      font-size: 12px;
      border: none;
      &:hover {
        opacity: 0.8;
      }
    }
    .decoration-item {
      padding-left: 0;
      background-color: rgba(223, 229, 235, 0.36);
      margin: 10px 0;
      .decoration-header {
        display: flex;
        justify-content: space-between;
        padding: 4px;
      }
    }
  }
  .dynamic_image_cfg_container{
      border: 1px solid #dedede;
      margin: 10px;
      position: relative;
  }
  .deldynamic_image{
    position: absolute;
    top: 10px;
    right: 10px;
  }
</style>
