import { render, staticRenderFns } from "./ChapterPreview.vue?vue&type=template&id=7f386a47&scoped=true&"
import script from "./ChapterPreview.vue?vue&type=script&lang=js&"
export * from "./ChapterPreview.vue?vue&type=script&lang=js&"
import style0 from "./ChapterPreview.vue?vue&type=style&index=0&id=7f386a47&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f386a47",
  null
  
)

export default component.exports