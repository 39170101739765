import katex from 'katex';
import { renderTian, renderFang, renderPinyin } from './specialElHelper';

const div = document.createElement('div');

const parser = (str) => {
  if (!str) {
    return '';
  }
  str = str.replace(/\$\$(.*?)\$\$/gi, (match, latex) => {
    let content = latex;
    div.innerHTML = content;
    content = div.innerText;
    try {
      content = katex.renderToString(content, {
        displayMode: true
      });
    } catch (err) {
      console.error('公式包含无法转换的符号', err);
    }
    return content;
  });
  div.innerHTML = str;
  div.querySelectorAll('[data-label="fang"], [data-label="tian"], [data-label="pinyin"]').forEach(node => {
    if (node.dataset.label === 'fang') {
      renderFang(node, {
        width: node.dataset.width,
        collapse: node.dataset.collapse,
        length: +node.dataset.length
      });
    }
    if (node.dataset.label === 'tian') {
      renderTian(node, {
        fontSize: node.dataset.fontSize,
        cellSize: node.dataset.cellSize,
        pinyin: node.dataset.pinyin,
        innerLineType: node.dataset.innerLineType,
        length: +node.dataset.length
      });
    }
    if (node.dataset.label === 'pinyin') {
      renderPinyin(node, {
        fontSize: node.dataset.fontSize,
        pinyin: node.dataset.pinyin,
        text: node.dataset.text
      });
    }
  });
  str = div.innerHTML;
  return str;
};

export {
  parser
};
