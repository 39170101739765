export const getDefaultBeforeOrAfter = () => {
  return {
    type: '',
    content: '',
    font_size: '',
    font_family_cn: '',
    font_family_en: '',
    font_family_num: '',
    font_color: { rgb: undefined, cmyk: [] },
    font_background: { rgb: undefined, cmyk: [] },
    decorations: []
  };
};

export const getDefautlStyle = () => {
  return {
    font_size: '',
    font_family_cn: '',
    font_family_en: '',
    font_family_num: '',
    text_bold: '',
    font_color: { rgb: undefined, cmyk: [] },
    line_spacing: '',
    text_align: '',
    font_background: { rgb: undefined, cmyk: [] },
    before: getDefaultBeforeOrAfter(),
    after: getDefaultBeforeOrAfter(),
  };
};

export const defaultColumn = () => {
  return {
    column_count: '',
    column_gap: '',
    line_type: '',
    line_weight: '',
    line_color: {
      rgb: '',
      cmyk: []
    }
  };
};

export const getDefaultDecoration = () => {
  return {
    position: '',
    type: '',
    id: '',
    url: '',
    alias_url: '',
    size: {
      width: '',
      height: ''
    },
    margin: {
      left: '',
      right: '',
      top: '',
    },
    offset: '',
    vertical_align: '',
    text: '',
    style: getDefautlStyle()
  };
};

export const getDefaultDynamicDecoration = () => {
  return {
    url: '',
    alias_url: '',
    size: {
      width: '',
      height: '',
    },
    range: {
      start: '',
      end: ''
    }
  };
};

export const defaultMargin = () => {
  return {
    margin_before: '',
    margin_type: '',
    offset: '',
    page_selector: '',
    relative_margin: '',
    data: [],
    fbd: { enable_fbd: false, code_template: '' },
  };
};

export const defualtPageNumber = () => {
  return {
    node_id: '',
    node_type: 'page_number',
    content: {
      text: '',
      url: '',
      alias_url: '',
      offset: ''
    },
    position: {
      x: '',
      y: ''
    },
    img_size: {
      width: '',
      height: ''
    },
    style: getDefautlStyle()
  };
};

export const defaultMarginImg = () => {
  return {
    node_id: '',
    node_type: 'image',
    content: {
      url: '',
      alias_url: ''
    },
    margin: {
      before: ''
    },
    img_size: {
      width: '',
      height: ''
    },
    position: {
      x: '0mm',
      y: '0mm',
    }
  };
};

export const defaultMarginText = () => {
  return {
    node_id: '',
    node_type: 'text',
    content: {
      text: '',
      align: ''
    },
    margin: {
      before: ''
    },
    style: getDefautlStyle()
  };
};

export const defaultKeyWord = () => {
  return {
    target: '',
    is_hidden: false,
    style: getDefautlStyle(),
    decorations: '',
    fbd_code_cfg: {
      enable_fbd: false,
      code_template: ''
    }
  };
};

export const defaultChapter = (identity = {}) => {
  return {
    ...identity,
    style: {
      _self: { ...getDefautlStyle(), line_height: '', text_decoration: '' }
    },
    display: {
      _self: true,
      body: true,
      qrcode: false
    },
    config: {
      _self: {
        column: {
          column_count: '',
          column_gap: '',
          line_type: '',
          column_weight: '',
          line_color: {
            rgb: '',
            cmyk: []
          }
        },
        hanging_space: '',
        margin: {
          before: '',
          after: '',
          top: '',
          bottom: '',
          left: ''
        },
        indent: '',
        is_in_column: '',
        page_break: '',
        decorations: [],
        is_chapter_num_zk: true
      },
      qrcode: {
        text: process.env.NODE_ENV === 'development' ? 'http://publish.hexin.im/api/pool/short/map?id={node_id}' : `${window.location.origin}/api/pool/short/map?id={node_id}`,
        display: '',
        size: {
          width: '15mm',
          height: '15mm'
        },
        align: ''
      },
    },
    html: {
      _self: {
        _keyword: []
      }
    },
    html_style_clean: {
      // 2021-05-07 默认不再清除 方正需求
      // _self: ['bold', 'italics', 'underline', 'strike', 'subscript', 'superscript', 'emphasis_double', 'emphasis_wave', 'emphasis_dot', 'text_align', 'indent']
    },
    fbd: {
      enable_fbd: false,
      code_template: '',
    }
  };
};

export const defaultParagraph = (identity = {}) => {
  return {
    ...identity,
    style: {
      _self: getDefautlStyle()
    },
    display: {
      _self: true
    },
    html: {
      _self: {
        _keyword: [],
        img: {
          display: '',
          align: '',
          insert_at: '',
          desc: {
            text: '',
            align: '',
            text_style: getDefautlStyle()
          }
        },
        table: {
          ...getDefautlStyle(),
          font_style: {
            top: getDefautlStyle(),
            left: getDefautlStyle(),
          },
          fill: {
            crma: {
              rgb: '',
              cmyk: []
            },
            top: {
              rgb: '',
              cmyk: []
            },
            left: {
              rgb: '',
              cmyk: []
            }
          },
          border: {
            outer: {
              type: '',
              color: {
                rgb: '',
                cmyk: []
              },
              weight: ''
            },
            inner: {
              type: '',
              color: {
                rgb: '',
                cmyk: []
              },
              weight: ''
            }
          }
        }
      }
    },
    config: {
      _self: {
        indent: '',
        hanging_space: '',
        margin: {
          top: '',
          bottom: '',
          left: ''
        },
      },
      fk: {
        group: [],
        margin: { top: '', bottom: '', left: '', right: '' },
        padding: { top: '', bottom: '', left: '', right: '' },
        background: { rgb: [], cmyk: [] },
        border_type: '',
        border_image: {
          header: '',
          footer: '',
          left: '',
          right: ''
        },
        border: { type: '' }
      }
    },
    html_style_clean: {
      // // 2021-05-07 默认不再清除 方正需求
      // _self: ['bold', 'italics', 'underline', 'strike', 'subscript', 'superscript', 'emphasis_double', 'emphasis_wave', 'emphasis_dot', 'text_align', 'indent']
    }
  };
};

export const defaultQuestion = (identity = {}) => {
  return {
    ...identity,
    style: {
      _self: getDefautlStyle(),
      body: getDefautlStyle(),
      serial_number: getDefautlStyle(),
      source: getDefautlStyle(),
      answer: getDefautlStyle(),
      analysis: getDefautlStyle(),
      choices: getDefautlStyle(),
      choice_letter: getDefautlStyle(),
      choice_option: getDefautlStyle(),
    },
    config: {
      body: {
        indent: '',
        blank_with_brackets: false,
        fbd: {
          enable_fbd: true,
          code_template: '',
        },
      },
      bracket: {
        autofill: '',
        float: '',
        length: '',
        length_cfg: {
          mode: 'full', // full space, 默认给 full
          length: 2, // 默认2
        },
        fbd: {
          enable_fbd: true,
          code_template: '',
        },
      },
      _self: {
        hanging_space: '',
        margin: { top: '', bottom: '', left: '' },
        indent: '',
        continuous_array: {
          effect: '',
          width: ''
        },
        children_dw: {
          per_line: '',
          spacing: []
        },
        blank_both_sides_space: '',
      },
      serial_number: {
        layout: '', pattern: '',
        margin: { before: '', after: '' },
        decorations: [],
        fbd: {
          enable_fbd: true,
          code_template: '',
        },
      },
      answer: {
        is_in_answer_space: '',
        indent: '',
        fbd: {
          enable_fbd: true,
          code_template: '',
        },
      },
      analysis: {
        indent: '',
        fbd: {
          enable_fbd: true,
          code_template: '',
        },
      },
      choices: {
        option_spacing: [],
        choice_per_line: '',
        letter_layout: '',
        image_option_letter_position: '',
        margin: {
          left: '',
          top: ''
        },
        fbd: {
          enable_fbd: true,
          code_template: '',
        },
      },
      choice_letter: {
        fbd: {
          enable_fbd: true,
          code_template: '',
        },
      },
      answer_space: {
        height: '',
        layout: 'default'
      },
      qrcode: {
        text: process.env.NODE_ENV === 'development' ? 'http://publish.hexin.im/api/pool/short/map?id={node_id}' : `${window.location.origin}/api/pool/short/map?id={node_id}`,
        display: '',
        size: {
          width: '15mm',
          height: '15mm'
        },
        align: ''
      },
      fk: {
        group: '',
        margin: { top: '', bottom: '', left: '', right: '' },
        padding: { top: '', bottom: '', left: '', right: '' },
        background: { rgb: [], cmyk: [] },
        border_type: '',
        border_image: {
          header: '',
          footer: '',
          left: '',
          right: ''
        },
        border: { type: '' }
      },
      content_split: {
        refer_order: 'default',
        refer_alternate: false,
        answer: {
          before: getDefaultBeforeOrAfter(),
          line_break: false,
          delimiter: ''
        },
        analysis: {
          before: getDefaultBeforeOrAfter(),
          line_break: false,
          delimiter: '',
        },
        cross_cfg: {
          answer_analysis_arrangement_mode: 'break_line'
        },
      }
    },
    html: {
      _self: {
        _keyword: [],
        img: {
          display: '', align: '', insert_at: '',
          margin: { left: '', right: '', top: '', bottom: '' },
          desc: {
            text: '',
            align: '',
            text_style: getDefautlStyle()
          },
        },
        table: {
          ...getDefautlStyle(),
          font_style: {
            top: getDefautlStyle(),
            left: getDefautlStyle(),
          },
          fill: {
            crma: {
              rgb: '',
              cmyk: []
            },
            top: {
              rgb: '',
              cmyk: []
            },
            left: {
              rgb: '',
              cmyk: []
            }
          },
          border: {
            outer: {
              type: '',
              color: {
                rgb: '',
                cmyk: []
              },
              weight: ''
            },
            inner: {
              type: '',
              color: {
                rgb: '',
                cmyk: []
              },
              weight: ''
            }
          }
        }
      },
      body: {
        _keyword: [],
        img: {
          display: undefined,
          align: undefined,
          insert_at: undefined,
          margin: {
            left: '',
            right: '',
            top: '',
            bottom: ''
          },
          desc: {
            text: '',
            align: '',
            text_style: getDefautlStyle()
          }
        },
      },
      answer: {
        _keyword: [],
        img: {
          display: '',
          align: '',
          insert_at: '',
          margin: {
            left: '',
            right: '',
            top: '',
            bottom: ''
          },
          desc: {
            text: '',
            align: '',
            text_style: getDefautlStyle()
          }
        },
      },
      analysis: {
        _keyword: [],
        img: {
          display: '',
          align: '',
          insert_at: '',
          margin: {
            left: '',
            right: '',
            top: '',
            bottom: ''
          },
          desc: {
            text: '',
            align: '',
            text_style: getDefautlStyle()
          }
        },
      },
      choices: {
        img: {
          display: '',
          align: '',
          insert_at: '',
          margin: {
            left: '',
            right: '',
            top: '',
            bottom: ''
          },
          desc: {
            text: '',
            align: '',
            text_style: getDefautlStyle()
          }
        },
      }
    },
    display: {
      _self: null,
      body: null,
      serial_number: null,
      source: null,
      answer: null,
      analysis: null,
      choices: null,
      choice_letter: null,
      choice_option: null,
      answer_space: null,
      qrcode: null
    },
    html_style_clean: {
      // 2021-05-07 默认不再清除 方正需求
      // _self: ['bold', 'italics', 'underline', 'strike', 'subscript', 'superscript', 'emphasis_double', 'emphasis_wave', 'emphasis_dot', 'text_align', 'indent'],
      // body: ['bold', 'italics', 'underline', 'strike', 'subscript', 'superscript', 'emphasis_double', 'emphasis_wave', 'emphasis_dot', 'text_align', 'indent'],
      // serial_number: ['bold', 'italics', 'underline', 'strike', 'subscript', 'superscript', 'emphasis_double', 'emphasis_wave', 'emphasis_dot', 'text_align', 'indent'],
      // source: ['bold', 'italics', 'underline', 'strike', 'subscript', 'superscript', 'emphasis_double', 'emphasis_wave', 'emphasis_dot', 'text_align', 'indent'],
      // answer: ['bold', 'italics', 'underline', 'strike', 'subscript', 'superscript', 'emphasis_double', 'emphasis_wave', 'emphasis_dot', 'text_align', 'indent'],
      // analysis: ['bold', 'italics', 'underline', 'strike', 'subscript', 'superscript', 'emphasis_double', 'emphasis_wave', 'emphasis_dot', 'text_align', 'indent'],
      // choices: ['bold', 'italics', 'underline', 'strike', 'subscript', 'superscript', 'emphasis_double', 'emphasis_wave', 'emphasis_dot', 'text_align', 'indent'],
    }
  };
};

export const defaultGroupStyle = (identity) => {
  return {
    ...identity,
    node_id_list: [],
    margin: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    padding: {
      top: '',
      bottom: '',
      left: '',
      right: ''
    },
    background: {
      rgb: '',
      cmyk: []
    },
    border_type: '',
    border_image: {
      header: '',
      footer: '',
      left: '',
      right: ''
    },
    border: {
      type: ''
    }
  };
};

export const defaultStyleBankChapter = () => {
  return {
    style_name: '',
    style_object: defaultChapter(),
  };
};

export const defaultext_cfg = () => {
  return {
    content: {
      code_template: '',
      local: '',
      is_in_column: '',
    }
  };
};
