<template>
  <div class="chapter-template">
    <div ref="preview" class="chapter-preview" :style="{ width: previewWidth }"></div>
  </div>
</template>

<script>
// import { BookRender } from 'book-preview'
import { NodeRender } from 'book-preview';
import { webFontFamily, replaceFontSizeMap } from '@/assets/styleTool/map';
import _ from 'lodash';

export default {
  props: {
    styleProp: {
      type: Object,
      required: true
    },
    jsonProp: {
      type: Array,
    }
  },
  mounted () {
    this.render();
  },
  data () {
    return {
      previewWidth: 0,
      nodeRender: new NodeRender({
        getFont: font => (webFontFamily)[font]
      })
    };
  },
  destroyed () {
    this.nodeRender.destroy();
  },
  computed: {
    content () {
      return this.jsonProp || [{
        node_id: '111',
        node_type: 'chapter',
        node_level: 1,
        content: {
          body: '<p>这是一个标题;</p>'
        }
      }];
    },
  },
  methods: {
    handleFontSize (arg) {
      if (Array.isArray(arg)) {
        arg.forEach(item => this.handleFontSize(item));
        return;
      }
      if (arg && typeof arg === 'object') {
        Object.keys(arg).forEach(key => {
          if (key === 'font_size' && replaceFontSizeMap[arg[key]]) {
            arg[key] = replaceFontSizeMap[arg[key]];
            return;
          }
          this.handleFontSize(arg[key]);
        });
      }
    },
    handleColumnWidth ({ components: [{ page_cfg: { page_core_size, global_column, global_column: { column_count, column_gap } }, node_batch_cfg_list }] }, [{ node_id }]) {
      const targetBatch = node_batch_cfg_list?.filter((item) => {
        return item?.node_id_list?.includes(node_id);
      });
      let columnCount = column_count;
      let columnGap = parseFloat(column_gap);
      let isInColumn = true;
      targetBatch?.forEach(({ config: { _self: { column: { column_count, column_gap }, is_in_column } } }) => {
        if (column_count) columnCount = column_count;
        if (column_gap) columnGap = parseFloat(column_gap);
        if (is_in_column === false) isInColumn = false;
      });
      global_column.column_count = 1;
      this.previewWidth = page_core_size.width = isInColumn ? (parseFloat(page_core_size.width) - columnGap) / columnCount + 'mm' : page_core_size.width;
    },
    handleBatch (config) {
      const { node_batch_cfg_list } = config.components[0];
      node_batch_cfg_list?.forEach(item => {
        if (!item.node_id_list) item.node_id_list = [];
      });
    },
    render () {
      const config = _.cloneDeep(this.styleProp);
      this.handleFontSize(config.components[0]); // 将前端不能识别的字号转为正常单位
      this.handleColumnWidth(config, this.content); // 预览节点宽度
      this.handleBatch(config);
      this.nodeRender
      .setData({ json: this.content, config })
      .preview({
        renderTo: this.$refs.preview
      });
    }
  },
  watch: {
    styleProp: {
      deep: true,
      handler () {
        this.render();
      }
    },
    jsonProp: {
      deep: true,
      handler () {
        this.render();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
  .chapter-template {
    overflow: auto;
    .chapter-preview {
      margin: 0 auto;
      border: 1px dashed #ccc;
    }
  }
</style>
