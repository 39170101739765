<template>
  <div class="column-config">
    <el-form :inline="true" size="mini" label-width="70px">
      <el-form-item label="分栏数：">
        <el-input type="number" class="style-tool-input" min="1" max="1000" step="1"
          v-model.number="value.column.column_count" clearable @change="columnCountChange"
          :placeholder="placeholder.column.column_count"
        />
      </el-form-item>
      <el-form-item label="栏间距：">
        <format-input v-model="value.column.column_gap" min="0" :placeholder="placeholder.column.column_gap" clearable></format-input>
      </el-form-item>
      <br>
      <el-form-item label="栏线类型：">
        <el-select v-model="value.column.line_type" class="style-tool-select" :placeholder="findKey(placeholder.column.line_type, columnLineType)" :popper-append-to-body="false" clearable>
          <el-option
            v-for="(value, key) in columnLineType"
            :key="value"
            :label="key"
            :value="value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="栏线粗细：">
        <el-select v-model="value.column.line_weight" class="style-tool-select" :placeholder="placeholder.column.line_weight" :popper-append-to-body="false" clearable>
          <el-option
            v-for="item in 7"
            :key="item"
            :label="item"
            :value="item">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="栏线颜色：">
        <Color :value="value.column.line_color" :placeholder="placeholder.column.line_color" defaultColor="rgb(0, 0, 0)" :globalColor="styleData.book_default_style.global_color" />
      </el-form-item>
      <br>
      <el-form-item label="跟随分栏：">
        <el-radio-group v-model="isInColumn">
          <el-radio :label="true">跟随分栏</el-radio>
          <el-radio :label="false">不跟随分栏</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import { findKey } from '@/scripts/helper';
import FormatInput from './FormatInput';
import Color from './Color';
export default {
  components: {
    FormatInput,
    Color
  },
  name: 'column-config',
  props: {
    value: {
      required: true,
      type: Object
    },
    styleData: {
      type: Object,
      default () {
        return {
          book_default_style: {
            global_color: []
          }
        };
      }
    },
    placeholder: {
      type: Object,
      default: function () {
        return {
          column: {
            column_count: '1',
            column_gap: '10mm',
            line_type: '无',
            line_color: {
              rgb: [0, 0, 0],
              cmyk: []
            }
          }
        };
      }
    }
  },
  computed: {
    isInColumn: {
      get () {
        const isInColumn = this.value.is_in_column;
        const placeholderIsInColumn = this.placeholder.is_in_column;
        return isInColumn !== '' ? isInColumn : placeholderIsInColumn !== '' ? placeholderIsInColumn : true;
      },
      set (val) {
        const placeholderIsInColumn = this.placeholder.is_in_column;
        const hasPlaceholderIsInColumn = placeholderIsInColumn === true || placeholderIsInColumn === false;
        if (hasPlaceholderIsInColumn && val === placeholderIsInColumn || !hasPlaceholderIsInColumn && val === true) {
          this.value.is_in_column = '';
          return;
        }
        this.value.is_in_column = val;
      }
    }
  },
  data () {
    return {
      columnLineType: {
        '无': 'none',
        '实线': 'solid',
        '短虚线': 'dashed',
        '双实线': 'double'
      }
    };
  },
  methods: {
    findKey,
    columnCountChange (val) {
      if (val !== '' && val < 1) this.value.column.column_count = '';
    },
    isInColumnChange (val) {
      this.value.is_in_column = val;
    }
  }
};
</script>
<style lang="scss" scoped>
.column-config {
  /deep/ .el-icon-circle-close {
    line-height: 17px;
  }
}
</style>
