export const catalog = {
  module: {
    header: {
      label: '页眉',
      value: 'header',
      type: [
        {
          label: '纯文字',
          value: 'text',
          model: [
            {
              label: '静态文本',
              value: 'static_text',
              img: require('./img/1.png')
            },
            {
              label: '动态文本',
              value: 'dynamic_text',
              img: require('./img/2.png')
            },
            {
              label: '动静态文本',
              value: 'sd_text',
              img: require('./img/3.png')
            }
          ]
        },
        {
          label: '纯图片',
          value: 'image',
          model: [
            {
              label: '纯图片（版心宽）',
              value: 'core_pic',
              img: require('./img/4.png')
            },
            {
              label: '纯图片（页宽）',
              value: 'page_pic',
              img: require('./img/5.png')
            },
          ]
        },
        {
          label: '底图+文本',
          value: 'background_text',
          model: [
            {
              label: '底图（版心宽）+静态文本',
              value: 'static_text_core_pic',
              img: require('./img/6.png')

            },
            {
              label: '底图（版心宽）+动态文本',
              value: 'dynamic_text_core_pic',
              img: require('./img/7.png')
            },
            {
              label: '底图（版心宽）+动静态文本',
              value: 'sd_text_core_pic',
              img: require('./img/8.png')
            },
            {
              label: '底图（页宽）+静态文本',
              value: 'static_text_width_pic',
              img: require('./img/9.png')
            },
            {
              label: '底图（页宽）+动态文本',
              value: 'dynamic_text_width_pic',
              img: require('./img/10.png')
            },
            {
              label: '底图（页宽）+动静态文本',
              value: 'sd_text_width_pic',
              img: require('./img/11.png')
            },
          ]
        },
        {
          label: '插图+文本',
          value: 'image_text',
          model: [
            {
              label: '插图+静态文本',
              value: 'static_text_pic',
              img: require('./img/12.png')
            },
            {
              label: '插图+动态文本',
              value: 'dynamic_text_pic',
              img: require('./img/13.png')
            },
            {
              label: '插图+动静态文本',
              value: 'sd_text_pic',
              img: require('./img/14.png')
            },
          ]
        },
        {
          label: '页码+文本',
          value: 'pagenum_text',
          model: [
            {
              label: '页码+静态文本',
              value: 'static_text_num',
              img: require('./img/15.png')
            },
            {
              label: '页码+动态文本',
              value: 'dynamic_text_num',
              img: require('./img/16.png')
            },
            {
              label: '页码+动静态文本',
              value: 'sd_text_num',
              img: require('./img/17.png')
            },
          ]
        },
        {
          label: '页码+文本+图',
          value: 'pagenum_text_image',
          model: [
            {
              label: '页码+动静态文本+插图',
              value: 'sd_text_num_pic',
              img: require('./img/18.png')
            },
            {
              label: '页码+动静态文本+底图(版心宽)',
              value: 'sd_text_num_core_pic',
              img: require('./img/19.png')
            },
            {
              label: '页码+动静态文本+底图(页宽)',
              value: 'sd_text_num_width_pic',
              img: require('./img/20.png')
            },
          ]
        }
      ]
    },

    footer: {
      label: '页脚',
      value: 'footer',
      type: [
        {
          label: '纯页码',
          value: 'pagenum',
          model: [
            {
              label: '纯页码',
              value: 'num',
              img: require('./img/21.png')
            }
          ]
        },
        {
          label: '插图+页码',
          value: 'image_pagenum',
          model: [
            {
              label: '插图+页码',
              value: 'num_pic',
              img: require('./img/22.png')
            },
          ]
        },
        {
          label: '底图+页码',
          value: 'background_pagenum',
          model: [

            {
              label: '底图（版心宽）+页码',
              value: 'num_core_pic',
              img: require('./img/23.png')
            },
            {
              label: '底图（页宽）页码',
              value: 'num_width_pic',
              img: require('./img/24.png')
            },
          ]
        },
        {
          label: '页码+文本',
          value: 'pagenum_text',
          model: [
            {
              label: '页码+静态文本',
              value: 'static_text_num',
              img: require('./img/25.png')
            },
            {
              label: '页码+动态文本',
              value: 'dynamic_text_num',
              img: require('./img/26.png')
            },
            {
              label: '页码+动静态文本',
              value: 'sd_text_num',
              img: require('./img/27.png')
            },
          ]
        },
        {
          label: '底图+文本+页码',
          value: 'background_text_pagenum',
          model: [
            {
              label: '底图（版心宽）+静态文本+页码',
              value: 'static_text_core_pic_num',
              img: require('./img/28.png')
            },
            {
              label: '底图（版心宽）+动态文本+页码',
              value: 'dynamic_text_core_pic_num',
              img: require('./img/29.png')
            },
            {
              label: '底图（版心宽）+动静态文本+页码',
              value: 'sd_text_core_pic_num',
              img: require('./img/30.png')
            },
            {
              label: '底图（页宽）+静态文本+页码',
              value: 'static_text_width_pic_num',
              img: require('./img/31.png')
            },
            {
              label: '底图（页宽）+动态文本+页码',
              value: 'dynamic_text_width_pic_num',
              img: require('./img/32.png')
            },
            {
              label: '底图（页宽）+动静态文本+页码',
              value: 'sd_text_width_pic_num',
              img: require('./img/33.png')
            }
          ]
        },
        {
          label: '插图+文本+页码',
          value: 'image_text_pagenum',
          model: [
            {
              label: '插图+静态文本+页码',
              value: 'static_text_num_pic',
              img: require('./img/34.png')
            },
            {
              label: '插图+动态文本+页码',
              value: 'dynamic_text_num_pic',
              img: require('./img/35.png')
            },
            {
              label: '插图+动静态文本+页码',
              value: 'sd_text_num_pic',
              img: require('./img/36.png')
            }
          ]
        },
      ]
    },

    sidebar: {
      label: '边栏',
      value: 'sidebar',
      model: [
        {
          label: '仅订口图',
          value: 'binding_side',
          img: require('./img/37.png')
        },
        {
          label: '仅切口图',
          value: 'cut_side',
          img: require('./img/38.png')
        },
        {
          label: '订切口图',
          value: 'binding_cut_side',
          img: require('./img/39.png')
        }
      ]
    },
    chapter: {
      label: '标题',
      value: 'chapter',
      model: [
        {
          label: '纯文字',
          value: 'text',
          img: require('./img/40.png')
        },
        {
          label: '纯图片',
          value: 'pic',
          img: require('./img/41.png')
        },
        {
          label: '底图+文字',
          value: 'text_background_pic',
          img: require('./img/42.png')
        },
        {
          label: '插图+文字',
          value: 'text_pic',
          img: require('./img/43.png')
        },
        {
          label: '底图+插图+文字',
          value: 'background_pic_text_pic',
          img: require('./img/44.png')
        }
      ]
    },
    paragraph: {
      label: '段落',
      value: 'paragraph',
      model: [
        {
          label: '缩进效果',
          value: 'indent',
          img: require('./img/45.png')
        },
        {
          label: '底色效果',
          value: 'background_color',
          img: require('./img/46.png')
        },
        {
          label: '文字底纹效果',
          value: 'background_text_color',
          img: require('./img/47.png')
        },
        {
          label: '方框效果',
          value: 'box',
          img: require('./img/48.png')
        },
      ]
    },
    question: {
      label: '试题',
      value: 'question',
      model: [
        {
          label: '全部题型',
          value: 'all_type',
        },
        {
          label: '选择题',
          value: 'choice',
        },
        {
          label: '填空题',
          value: 'blank',
        },
        {
          label: '判断题',
          value: 'true_false',
        },
        {
          label: '材料题',
          value: 'material',
        },
        {
          label: '完形填空',
          value: 'cloze',
        },
        {
          label: '作答题',
          value: 'other',
        },
      ]
    },
    borderStyle: {
      label: '方框',
      value: 'borderStyle'
    }
  }
};
